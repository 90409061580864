// src/Slider.js
import React, { useState, useEffect } from 'react';
import '../styles/slider.scss'; 
import Phnompenh from '../images/Cambodia-Phnompenh.png';
import Speakingimage from '../images/speakingimage.png';

const itemCount = 5

const images = [
    Phnompenh,
    Speakingimage,
];

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
      }, 3000); // 3秒ごとに切り替え

    return () => clearInterval(interval); // クリーンアップ
  }, []);

  return (
    <div className="slider">
      <div
        className="slider-inner"
        style={{ transform: `translateX(-${(currentIndex % images.length) * 100}%)` }}
      >
        {images.map((image, index) => (
          <div className="slider-item" key={index}>
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;