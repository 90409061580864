import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './header.js';
import Footer from './footer.js';

/* import */
import Top from './Top.js';
import Form from './form.js';
import ScrollToTop from './components/scrolltotop.js';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Top />} />
            <Route path="/form" element={<Form />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
