import React, { useEffect } from "react";
import logo from './images/logo.png';
import './styles/header.scss';
import gsap from 'gsap';

const Header = () => {
    useEffect(() => {
        const loadingTimeout = setTimeout(() => {
            const $loading = document.querySelector('.js-loading');
            if ($loading) {
                $loading.classList.add('loaded');
                setTimeout(textAnimStart, 1000);
                setTimeout(filterAnimStart, 500);
            }
        }, 1000);

        // Clean up the timeout on component unmount
        return () => clearTimeout(loadingTimeout);
    }, []);

    const textAnimStart = () => {
        // Your text animation logic here
        console.log('Text animation started');
    };

    const filterAnimStart = () => {
        // Your filter animation logic here
        console.log('Filter animation started');
    };


    return (
        <header className="header">
            <div className="header__logobox">
                <img src={logo} alt="Logo" className="header__logobox__logo" />
            </div>
            {/* <div class="loading">
                <div class="loading__img"> 
                    <img src={logo} alt="" /> 
                </div>
                <div class="loading__bar"></div>
                <div class="loading__bg"></div>
            </div> */}
        </header>
    );
}

export default Header;