import React, { useState, useEffect } from 'react';
import '../styles/conversioncontens.scss';
import Button from './button';


const Conversioncontens = () => {

    return(
        <>
        <div className="conversioncontents">
            <div className="conversioncontents__textbox">
            <p>
            英文法の知識も！英単語の量も！英語を読めなくても！英語学力アップの「勉強」も！！ナシ。<br />
            簡単な英会話力アップの「練習」のみでネイティブと会話ができるようになります！！！<br />
            まずは無料でこちらから相談</p>
            </div>
            <Button>無料で相談する</Button>
        </div>
        </>
    );

}

export default Conversioncontens;

