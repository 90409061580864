/* LPページ */
import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Slider from './components/slider';
import Phnompenh from './images/Cambodia-Phnompenh.png';
import './styles/top.scss'; 
import Conversioncontens from './components/conversioncontents';
import headersvg from './images/headersvg.svg';
import popup_right1 from './images/right-1.png';
import popup_right2 from './images/right-2.png';
import popup_left1 from './images/left-1.png';
import popup_left2 from './images/left-2.png';
import Button from './components/button';
import englishimg1 from './images/english-img1.jpg';
import englishimg2 from './images/english-img2.jpg';
import englishimg3 from './images/english-img3.jpg';
import traveleng from './images/traveleng.jpeg';
import nativeeng from './images/nativeeng.jpg';
import coremethod from './images/coremethod.jpg';

function Top() {

    return (
        <>
        <div className="for-pc">
            <section className="KeyVisual">
                <img className="KeyVisual__backimage" src={headersvg} />
                <div className="KeyVisual__container">
                    <div className="KeyVisual__text">
                        <div className="KeyVisual__text__contents">
                            <h3>旅行で喋れないを解消します</h3>
                            <p>旅サクエイゴは、旅行シーンに特化した英会話スクールサービスです。<br />
                                約 4ヶ月間のプログラムで、あなたの旅行の不便を解消します。</p>  
                            <Button>無料で相談する</Button>  
                        </div>
                    </div>
                    <div className="KeyVisual__image">
                        <Slider />
                    </div>
                </div>
            </section>
            <section className="Interest">
                <h3 className="Interest__title">
                    英語を話すのに英作文が必要だと思っていませんか？
                </h3>
                <div className="Interest__hookbox">
                    <div className="Interest__hookbox__popup" style={{ backgroundImage: `url(${popup_left1})` }}>
                        <p>単語が多くて覚えられない。</p>
                    </div>
                    <div className="Interest__hookbox__popup" style={{ backgroundImage: `url(${popup_left2})` }}>
                        <p>英文法がムズカシすぎる</p>                        
                    </div>
                    <div className="Interest__hookbox__popup" style={{ backgroundImage: `url(${popup_right1})` }}>
                        <p>英語を学んでも<br />ネイティブに伝わらない</p>                               
                    </div>
                    <div className="Interest__hookbox__popup" style={{ backgroundImage: `url(${popup_right2})` }}>
                        <p>旅行する時に英語が喋れない</p>                          
                    </div>
                </div>

                <h3 className="Interest__contentsboxtitle">英語を話すのに実は「英作文能力」は必要ないんです・・・</h3>
                
                <div className="Interest__contentsbox">
                    <div className="Interest__contentsbox__imgwithtext">
                        <div className="Interest__contentsbox__imgwithtext__imgcontainer">
                            <img src={traveleng} />
                        </div>
                        <p>旅行の時に必要な英語のみを習得していくので、使う頻度の高い英語表現から習得でき、早い段階から話せている実感が持てる</p>
                    </div>
                    <div className="Interest__contentsbox__imgwithtext">
                        <div className="Interest__contentsbox__imgwithtext__imgcontainer">
                            <img src={nativeeng} />                            
                        </div>
                        <p>覚えていく旅行英語は、すべて自然なネイティブ表現なので、合っているか間違っているか考える必要なく、自信を持って使っていける</p>
                    </div>
                    <div className="Interest__contentsbox__imgwithtext">
                        <div className="Interest__contentsbox__imgwithtext__imgcontainer">
                            <img src={coremethod} />
                        </div>
                        <p>ネイティブ英語の発声、リズム、発音を同時に出来るようになるメソッドを使って練習するので、
【通じる】英語が話せるようになる</p>
                    </div>
                </div>
                <div>

                </div>
            </section>
            <Conversioncontens />
            <section className="Features">
                <h3>旅サクエイゴの特徴</h3>
                <div className="Features__contentsbox">
                    <div className="Features__contentsbox__imgcontainer">
                        <img src={englishimg1} />
                    </div>
                    <div className="Features__contentsbox__textcontainer">
                        <div className="Features__contentsbox__textcontainer__numb">
                            <p>01</p>
                        </div>

                        <h4 className="Features__contentsbox__textcontainer__title">
                            自分で英作文を考えなくて良い
                        </h4>
                        <p>
                            ネイティブ講師が英作文を作ってくれるため、自分で文章を毎度毎度考える必要がなく、サクッと英語が上達することができます。
                        </p>
                    </div>

                </div>
                <div className="Features__contentsbox">
                    <div className="Features__contentsbox__imgcontainer">
                        <img src={englishimg2} />
                    </div>
                    <div className="Features__contentsbox__textcontainer">
                        <div className="Features__contentsbox__textcontainer__numb">
                            <p>02</p>
                        </div>

                        <h4 className="Features__contentsbox__textcontainer__title">
                            英単語や英文法を勉強しなくて良い
                        </h4>
                        <p>
                            ネイティブ英語の発声、リズム、発音を同時に練習してもらうため、単語や文法の勉強の必要なく、英語が上達します。
                        </p>
                    </div>

                </div>
                <div className="Features__contentsbox">
                    <div className="Features__contentsbox__imgcontainer">
                        <img src={englishimg3} />
                    </div>
                    <div className="Features__contentsbox__textcontainer">
                        <div className="Features__contentsbox__textcontainer__numb">
                            <p>03</p>
                        </div>

                        <h4 className="Features__contentsbox__textcontainer__title">
                            自走できるようになる
                        </h4>
                        <p>
                        勉強よりも・・・「練習」に重きを置いており、英語の正しい勉強法を理解し、自分で練習できるまでサポートをさせていただきます。
                        </p>
                    </div>

                </div>
            </section>
            <section className="Curriculum">
                <h3>旅サクエイゴのカリキュラム</h3>
                <div className="Curriculum__contentsbox">
                    <div className="Curriculum__contentsbox__1stcaption">
                        <p>２クール繰り返します</p>
                    </div>
                    <div className="Curriculum__contentsbox__schedule">
                        <div className="Curriculum__contentsbox__schedule__1stbox">
                            <ul>
                                <li className="Curriculum__contentsbox__schedule__1stbox--red first">1週目</li>
                                <li className="Curriculum__contentsbox__schedule__1stbox--red">2週目</li>
                                <li className="Curriculum__contentsbox__schedule__1stbox--red">3週目</li>
                                <li className="Curriculum__contentsbox__schedule__1stbox--red">4週目</li>
                                <li className="Curriculum__contentsbox__schedule__1stbox--red">5週目</li>
                                <li className="Curriculum__contentsbox__schedule__1stbox--red last">6週目</li>
                                <li className="Curriculum__contentsbox__schedule__1stbox--yellow first">7週目</li>
                                <li className="Curriculum__contentsbox__schedule__1stbox--yellow last">8週目</li>
                            </ul>
                        </div>
                        <div className="Curriculum__contentsbox__schedule__lapcaption">
                            <div className="Curriculum__contentsbox__schedule__lapcaption--1st">
                                <h4>シチュエーション別スピーキング練習</h4>
                            </div>
                            <div className="Curriculum__contentsbox__schedule__lapcaption--2nd">
                                <h4>ネイティブ講師によるロープレ</h4>
                            </div>
                        </div>
                        <div className="Curriculum__contentsbox__schedule__2ndbox">
                            <ul>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--red first">9週目</li>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--red">10週目</li>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--red">11週目</li>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--red">12週目</li>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--red">13週目</li>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--red last">14週目</li>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--yellow first">15週目</li>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--yellow last">16週目</li>
                            </ul>
                        </div>
                    </div>
                    <div className="Curriculum__contentsbox__2ndcaption">
                        <p>To travel</p>
                    </div>
                </div>
            </section>
            <Conversioncontens />
            <section className="Flow">
                <h3>無料カウンセリング申込後の流れ</h3>
                <div className="Flow__stepbox">
                    <div class="Flow__stepbox__nomb">
                        <p>STEP</p>
                        <h4>01</h4>
                    </div>

                    <div className="Flow__stepbox__textbox">
                        <p>無料カウンセリングの申込後に弊社から、日時確認の連絡をさせていただきます。</p>
                    </div>
                </div>

                <div className="Flow__stepbox">
                    <div class="Flow__stepbox__nomb">
                        <p>STEP</p>
                        <h4>02</h4>
                    </div>

                    <div className="Flow__stepbox__textbox">
                        <p>無料カウンセリングにて、具体的な講義内容や、コースのご説明をいたします。</p>
                    </div>
                </div>
                <div className="Flow__stepbox">
                    <div class="Flow__stepbox__nomb">
                        <p>STEP</p>
                        <h4>03</h4>
                    </div>

                    <div className="Flow__stepbox__textbox">
                        <p>1回1時間✖️週2回✖️2ヶ月を2クールで、合計32回のオンライン練習を実施いたします。</p>
                    </div>
                </div>
                <div className="Flow__stepbox">
                    <div class="Flow__stepbox__nomb">
                        <p>STEP</p>
                        <h4>04</h4>
                    </div>

                    <div className="Flow__stepbox__textbox">
                        <p>ネイティブ講師と実際に話をする模擬体験クラスで練習した英語が通じるかを試していただきます。</p>
                    </div>
                </div>
                <div className="Flow__stepbox">
                    <div class="Flow__stepbox__nomb">
                        <p>STEP</p>
                        <h4>05</h4>
                    </div>

                    <div className="Flow__stepbox__textbox">
                        <p>練習した英語を使いながら、旅行に行っていただき実際にネイティブと会話していただきます。</p>
                    </div>
                </div>
            </section>
            <Conversioncontens />
        </div>
        <div className="for-sp">
            <section className="KeyVisual">
                <img className="KeyVisual__backimage" src={headersvg} />
                <div className="KeyVisual__container">
                    <div className="KeyVisual__image">
                        <Slider />
                    </div>
                    <div className="KeyVisual__text">
                        <div className="KeyVisual__text__contents">
                            <h3>旅行で喋れないを解消します</h3>
                            <p>旅サクエイゴは、旅行シーンに特化した英会話スクールサービスです。<br />
                                約 4ヶ月間のプログラムで、あなたの旅行の不便を解消します。</p>  
                            <Button>無料で相談する</Button>  
                        </div>
                    </div>
                </div>
            </section>
            <section className="Interest">
                <h3 className="Interest__title">
                    英語を話すのに英作文が必要だと思っていませんか？
                </h3>
                <div className="Interest__hookbox">
                    <div className="Interest__hookbox__popup" style={{ backgroundImage: `url(${popup_left1})` }}>
                        <p>単語が多くて覚えられない。</p>
                    </div>
                    <div className="Interest__hookbox__popup" style={{ backgroundImage: `url(${popup_left2})` }}>
                        <p>英文法がムズカシすぎる</p>                        
                    </div>
                    <div className="Interest__hookbox__popup" style={{ backgroundImage: `url(${popup_right1})` }}>
                        <p>英語を学んでも<br />ネイティブに伝わらない</p>                               
                    </div>
                    <div className="Interest__hookbox__popup" style={{ backgroundImage: `url(${popup_right2})` }}>
                        <p>旅行する時に英語が喋れない</p>                          
                    </div>
                </div>

                <h3 className="Interest__contentsboxtitle">英語を話すのに実は「英作文能力」は必要ないんです・・・</h3>
                
                <div className="Interest__contentsbox">
                    <div className="Interest__contentsbox__imgwithtext">
                        <div className="Interest__contentsbox__imgwithtext__imgcontainer">
                            <img src={traveleng} />
                        </div>
                        <p>旅行の時に必要な英語のみを習得していくので、使う頻度の高い英語表現から習得でき、早い段階から話せている実感が持てる</p>
                    </div>
                    <div className="Interest__contentsbox__imgwithtext">
                        <div className="Interest__contentsbox__imgwithtext__imgcontainer">
                            <img src={nativeeng} />                            
                        </div>
                        <p>覚えていく旅行英語は、すべて自然なネイティブ表現なので、合っているか間違っているか考える必要なく、自信を持って使っていける</p>
                    </div>
                    <div className="Interest__contentsbox__imgwithtext">
                        <div className="Interest__contentsbox__imgwithtext__imgcontainer">
                            <img src={coremethod} />
                        </div>
                        <p>ネイティブ英語の発声、リズム、発音を同時に出来るようになるメソッドを使って練習するので、
【通じる】英語が話せるようになる</p>
                    </div>
                </div>
                <div>

                </div>
            </section>
            <Conversioncontens />
            <section className="Features">
                <h3>旅サクエイゴの特徴</h3>
                <div className="Features__contentsbox">
                    <div className="Features__contentsbox__imgcontainer">
                        <img src={englishimg1} />
                    </div>
                    <div className="Features__contentsbox__textcontainer">
                        <div className="Features__contentsbox__textcontainer__titlebox">
                            <div className="Features__contentsbox__textcontainer__numb">
                                <p>01</p>
                            </div>
                            <h4 className="Features__contentsbox__textcontainer__title">
                                自分で英作文を考えなくて良い
                            </h4>
                        </div>
                        <p className="Features__contentsbox__textcontainer__caption">
                            ネイティブ講師が英作文を作ってくれるため、自分で文章を毎度毎度考える必要がなく、サクッと英語が上達することができます。
                        </p>
                    </div>

                </div>
                <div className="Features__contentsbox">
                    <div className="Features__contentsbox__imgcontainer">
                        <img src={englishimg2} />
                    </div>
                    <div className="Features__contentsbox__textcontainer">
                        <div className="Features__contentsbox__textcontainer__titlebox">
                            <div className="Features__contentsbox__textcontainer__numb">
                                <p>02</p>
                            </div>

                            <h4 className="Features__contentsbox__textcontainer__title">
                                英単語や英文法を勉強しなくて良い
                            </h4>
                        </div>
                        <p className="Features__contentsbox__textcontainer__caption">
                            ネイティブ英語の発声、リズム、発音を同時に練習してもらうため、単語や文法の勉強の必要なく、英語が上達します。
                        </p>
                    </div>

                </div>
                <div className="Features__contentsbox">
                    <div className="Features__contentsbox__imgcontainer">
                        <img src={englishimg3} />
                    </div>
                    <div className="Features__contentsbox__textcontainer">
                        <div className="Features__contentsbox__textcontainer__titlebox">
                            <div className="Features__contentsbox__textcontainer__numb">
                                <p>03</p>
                            </div>

                            <h4 className="Features__contentsbox__textcontainer__title">
                                自走できるようになる
                            </h4>
                        </div>
                        <p className="Features__contentsbox__textcontainer__caption">
                        勉強よりも・・・「練習」に重きを置いており、英語の正しい勉強法を理解し、自分で練習できるまでサポートをさせていただきます。
                        </p>
                    </div>

                </div>
            </section>
            <section className="Curriculum">
                <h3>旅サクエイゴのカリキュラム</h3>
                <div className="Curriculum__contentsbox">
                    <div className="Curriculum__contentsbox__1stcaption">
                        <p>２クール繰り返します</p>
                    </div>
                    <div className="Curriculum__contentsbox__schedule">
                        <div className="Curriculum__contentsbox__schedule__1stbox">
                            <ul>
                                <li className="Curriculum__contentsbox__schedule__1stbox--red first">1週目</li>
                                <li className="Curriculum__contentsbox__schedule__1stbox--red">2週目</li>
                                <li className="Curriculum__contentsbox__schedule__1stbox--red">3週目</li>
                                <li className="Curriculum__contentsbox__schedule__1stbox--red last">4週目</li>
                                <li className="Curriculum__contentsbox__schedule__1stbox--red first">5週目</li>
                                <li className="Curriculum__contentsbox__schedule__1stbox--red last">6週目</li>
                                <li className="Curriculum__contentsbox__schedule__1stbox--yellow first">7週目</li>
                                <li className="Curriculum__contentsbox__schedule__1stbox--yellow last">8週目</li>
                            </ul>
                        </div>
                        <div className="Curriculum__contentsbox__schedule__lapcaption">
                            <div className="Curriculum__contentsbox__schedule__lapcaption--1st">
                                <h4>シチュエーション別スピーキング練習</h4>
                            </div>
                            <div className="Curriculum__contentsbox__schedule__lapcaption--2nd">
                                <h4>ネイティブ講師によるロープレ</h4>
                            </div>
                        </div>
                        <div className="Curriculum__contentsbox__schedule__2ndbox">
                            <ul>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--red first">9 週目</li>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--red">10週目</li>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--red ">11週目</li>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--red last">12週目</li>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--red first">13週目</li>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--red last">14週目</li>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--yellow first">15週目</li>
                                <li className="Curriculum__contentsbox__schedule__2ndbox--yellow last">16週目</li>
                            </ul>
                        </div>
                    </div>
                    <div className="Curriculum__contentsbox__2ndcaption">
                        <p>To travel</p>
                    </div>
                </div>
            </section>
            <Conversioncontens />
            <section className="Flow">
                <h3>無料カウンセリング申込後の流れ</h3>
                <div className="Flow__stepbox">
                    <div class="Flow__stepbox__nomb">
                        <p>STEP</p>
                        <h4>01</h4>
                    </div>

                    <div className="Flow__stepbox__textbox">
                        <p>無料カウンセリングの申込後に弊社から、日時確認の連絡をさせていただきます。</p>
                    </div>
                </div>

                <div className="Flow__stepbox">
                    <div class="Flow__stepbox__nomb">
                        <p>STEP</p>
                        <h4>02</h4>
                    </div>

                    <div className="Flow__stepbox__textbox">
                        <p>無料カウンセリングにて、具体的な講義内容や、コースのご説明をいたします。</p>
                    </div>
                </div>
                <div className="Flow__stepbox">
                    <div class="Flow__stepbox__nomb">
                        <p>STEP</p>
                        <h4>03</h4>
                    </div>

                    <div className="Flow__stepbox__textbox">
                        <p>1回1時間✖️週2回✖️2ヶ月を2クールで、合計32回のオンライン練習を実施いたします。</p>
                    </div>
                </div>
                <div className="Flow__stepbox">
                    <div class="Flow__stepbox__nomb">
                        <p>STEP</p>
                        <h4>04</h4>
                    </div>

                    <div className="Flow__stepbox__textbox">
                        <p>ネイティブ講師と実際に話をする模擬体験クラスで練習した英語が通じるかを試していただきます。</p>
                    </div>
                </div>
                <div className="Flow__stepbox">
                    <div class="Flow__stepbox__nomb">
                        <p>STEP</p>
                        <h4>05</h4>
                    </div>

                    <div className="Flow__stepbox__textbox">
                        <p>練習した英語を使いながら、旅行に行っていただき実際にネイティブと会話していただきます。</p>
                    </div>
                </div>
            </section>
            <Conversioncontens />
        </div>
        </>
    );
}

export default Top;