import React, { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css"
import DatePicker, { registerLocale } from "react-datepicker"
import ja from 'date-fns/locale/ja';

registerLocale('ja', ja);

const availableTimes = {
    '2024-07-02': ['16:00 - 17:00', '18:00 - 19:00'],
    // 他の日付や時間帯を追加可能
};

const sendEmail = (date, time) => {
    // メール送信のロジックをここに追加
    console.log(`予約メールが送信されました: 日付 ${date}, 時間 ${time}`);
};

const Form = () => {
    const Today = new Date();
    const [date, setDate] = useState(Today);
    const [times, setTimes] = useState([]);
    const [selectedTime, setSelectedTime] = useState("");

    useEffect(() => {
        const formattedDate = date.toISOString().split('T')[0];
        setTimes(availableTimes[formattedDate] || []);
    }, [date]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedTime) {
            const formattedDate = date.toISOString().split('T')[0];
            sendEmail(formattedDate, selectedTime);
            setTimes(times.filter(time => time !== selectedTime));
            setSelectedTime("");
        }
    };

    return (
        <div className="ui container" id="container">
            <div className="Search__Form">
                <form className="ui form segment" onSubmit={handleSubmit}>
                    <div className="field">
                        <label><i className="calendar alternate outline icon"></i>無料カウンセリング希望日</label>
                        <DatePicker
                            dateFormat="yyyy/MM/dd"
                            locale='ja'
                            selected={date}
                            minDate={Today}
                            onChange={(selectedDate) => setDate(selectedDate || Today)}
                        />
                    </div>
                    {times.length > 0 && (
                        <div className="field">
                            <label>予約可能時間</label>
                            <select value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)}>
                                <option value="">時間を選択してください</option>
                                {times.map((time, index) => (
                                    <option key={index} value={time}>{time}</option>
                                ))}
                            </select>
                        </div>
                    )}
                    <div className="Search__Button">
                        <button type="submit" className="Search__Button__Design">
                            <i className="search icon"></i>ご予約可能時間を検索する
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Form;